import './AboutMe.css';
import book from '../../images/book.jpg';
import me from '../../images/me.jpg';

export default function AboutMe () {
  return (
    <section className='about-me'>
      <div className='about-me__content'>

        <article className='about-me__text'>
          <img className='about-me__img' src={book} alt='стоит девушка в зеленом пиджаке' />
          <h2 className='about-me__heading'>Обо мне</h2>
          <div>
            <p className='about-me__paragraph'>Меня зовут Дарья Литуева, я квалифицированный врач анестезиолог-реаниматолог. В 2016г. окончила Медицинский Университет. Также имею высшее образование по специальности “Психолог-педагог”. А в 2023г. прошла профессиональную переподготовку по специальности “Нутрициология”и “Health-коучинг”.</p>
            <p className='about-me__paragraph'>Работала и работаю практикующим врачом в государственной больнице и в частной клинике. Всегда хотела в разных смыслах помогать людям, просто вектор направленности периодически менялся. И я считаю это нормальным.</p>
            <p className='about-me__paragraph'>Я выступаю за осознанность в отношении себя. И могу стать союзником для вас в таких темах, как:</p>
            <p className='about-me__paragraph'>Вопросы здорового образа жизни. Как к этому прийти, от чего оттолкнуться и на что опираться. Как приумножить свои знания в этой теме, если она уже вам знакома.</p>
            <p className='about-me__paragraph'>Вопросы лишнего веса. Почему вес не уходит, почему стоит. Как начать успешно идти к заветной мечте и главное, как сохранить полученный результат.</p>
            <p className='about-me__paragraph'>Вопросы плохого самочувствия, когда «всё вроде бы хорошо, но по утрам встать сложно, а вечером не заснуть». Когда «разбитость и психосоматика» — это не пустые слова для вас и вы очень хотите это изменить.</p>
          </div>
        </article>

        <article className='about-me__text'>
          <img className='about-me__img' src={me} alt='девушка в халате врача пишет в ежедневник' />
          <h2 className='about-me__heading'>Образование</h2>
          <div>
            <p className='about-me__paragraph'>Первое высшие образование я получила в 2013 г. Российский государственный педагогический университет им. А. И. Герцена. Психолог-педагог.</p>
            <p className='about-me__paragraph'>Второе высшие образование. Северо-Западный государственный медицинский университет им. И. И. Мечникова, 2016 г. Закончила лечебный факультет. Я врач.</p>
            <p className='about-me__paragraph'>Ординатура анестезиолог-реаниматолог. Московский областной научно-исследовательский клинический институт имени М. Ф. Владимирского, 2018 г.</p>
            <p className='about-me__paragraph'>Институт Интегративной Нутрициологии IFN, курс “Нутрициология”, 2023 г.</p>
            <p className='about-me__paragraph'>Университет Образовательной Медицины, программа “Превентивное и персонализированное управление здоровьем”. Health-коуч, 2023 г.</p>
          </div>
        </article>

      </div>
    </section>
  )
};
