import '../MyStory/MyStory.css';
import './MyStoryA.css';
import prom from '../../images/mystory/prom.JPG';
import dance from '../../images/mystory/dance.JPG';
import study from '../../images/mystory/study.PNG';

export default function MyStoryA () {
  return (
    <section className='myStoryA'>
      <div className='myStory__content'>

        <div className='myStoryA__m'>
          <div className='myStoryA__text'>
            <p className='myStory__parag'>Все что было в раннем детстве помню плохо, отдельными карточками воспоминаний. Ровно как и начальную школу. Но помню как в 12 лет приехала от бабушки и была весьма внушительных размеров для своего возраста. Это был мой первый набор веса за счет начала пубертатного периода. Когда бушуют первые взрослые гормоны, а подросток подкрепляет их булочками со сгущенкой, как это делала я, то вот и получается вес в 83 кг к концу 11 класса, в моем случае к 16 годам. Тогда меня это не особо волновало, но подниматься бегом по лестнице уже было тяжело, а бегать и привлекать внимание мне хотелось, поэтому в 17 лет я впервые решила похудеть. Оглядываясь на тот период, могу сказать, что делала я это весьма неплохо с точки зрения здоровья. Я вкусно завтракала: кашкой или яйцами, вкушала бутерброды с сыром, заедала помидором и шла в бассейн. Затем шла в сауну. Затем обедала: рыбой или мясом с гарниром, запивая чаем с мёдом. Потом бегала или танцевала, а ужинала салатом с кефиром. Не знаю откуда я взяла тогда этот рацион, но мне было хорошо и полезно в целом. Похудела я на свои первые 20 кг. </p>
            <p className='myStory__parag'>Думала, навсегда. Но нет. В течение обучения в двух университетах худела я и полнела несколько раз на те же 20 кг. Изучала белки, жиры и углеводы. Спортивное питание, разные физические нагрузки. Расшатывала свою гормональную систему, изучала вопросы психологии, связанные с пищевыми зависимостями, но постоянно возвращалась к заветным цифрам.</p>
          </div>

          <div className='myStoryA__photo'>
            <img src={prom} className='myStoryA__img' alt="девушка на выпускном в красном платье" />
            <img src={study} className='myStoryA__img' alt="девушка в университете в медицинском халате" />
            <img src={dance} className='myStoryA__img' alt="девушка танцует в желтом платье" />
          </div>
        </div>
    </div>
    </section>
  )
}